import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import * as PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Container from '../components/container'
import Wrapper from '../components/wrapper/wrapper'
import { GlobalStyle } from '../utils/global'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const fourofourPage = {
  marginTop: '300px',
}

class BlogyIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const boards = get(this, 'props.data.allContentfulBoards.edges')

    return (
      <Container>
        <Wrapper>
          <div style={fourofourPage}>
            <a href="https://www.crea.ca/">
              <img
                style={{ paddingBottom: '20px' }}
                src={require('../images/logo_crea_EN.svg')}
              />
            </a>
            <h1>...Opps, Page Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... </p>
            <p>You can return back to</p>
            <h3>
              <a href="https://www.crea.ca/">CREA.ca</a>
            </h3>
            <h3>
              <a href="https://creastats.crea.ca/">CREAStats.ca</a>
            </h3>
          </div>
        </Wrapper>
      </Container>
    )
  }
}

BlogyIndex.propTypes = propTypes

export default BlogyIndex

export const pageQuery = graphql`
  query BoardIndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allContentfulBoards {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
